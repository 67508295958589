import React from "react";

import Layout from "../../../components/theme/layout";
import Seo from "../../../components/seo";
import styled from "styled-components";

import { StaticImage } from "gatsby-plugin-image";
import '../case-study-page.scss'

import CtaSection from "../../../components/cta-section/cta-section.component";

const AJProdukterCaseStudyPage = () => (
    <Layout>
        <Seo title='AJ Produkter Case Study' />

        <div>
            <HeroWrapper>
                <h2>AJ Produkter created interactive 'shoppable' product guides for a smoother digital shopping experience</h2>
                <a href="https://www.ajprodukter.se/kampanjer/personlig-service-ett-samtal-bort/tips-for-en-battre-kontorsmiljo">
                <WebsiteContainer>
                    <StaticImage
                        className="aj-website"
                        style={{
                            gridArea: '1/1'
                        }}
                        src="../../../images/customers/aj produkter/aj-website.jpg"
                        alt="AJ Produkter website"
                        loading="eager"
                        placeholder="none"
                    />
                    <UseCaseSliderItemOverlay>
                        <span></span>
                    </UseCaseSliderItemOverlay>
                </WebsiteContainer>
                </a>

            </HeroWrapper>

            <StatsWrapper>
                <Stat>
                    <h2>70%</h2>
                    <p>of visitors engaged with interactive content</p>
                </Stat>
                <Stat>
                    <h2>42%</h2>
                    <p>of visitors landed on product pages</p>
                </Stat>
            </StatsWrapper>

            <AboutCase>
                <div>
                    <StaticImage
                        className="case-logo"
                        src="../../../images/logos/AJ-logo.svg"
                        alt="AJ Produkter logo"
                        loading="eager"
                        placeholder="none"
                    />
                </div>
                <div>
                    <p><strong>AJ Produkter is a leading Swedish brand in the retail office equipment market.</strong></p>
                    <p>AJ Produkter was founded by Anders Johansson in 1975, the company started in his parents basement in Hyltebruk, Sweden.</p>
                    <p>Since then the company has grown and is now represented in 19 countries, selling 15 000 products, with more than 20 subsidiaries, 3 factories and more than 1 100 employees.</p>
                </div>
            </AboutCase>

            <CaseInfo>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/dXY1Z9js_T0?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <div>
                    <h3>The challenge</h3>
                    <p>Like many eCommerce brands, AJ produkter do not have physical stores to sell their products, so the digital shopping experience is vital for sales.</p>
                    <p>With 15 000 products to sell online, trying to show the right product, to the right customer at the right time is an extremely difficult task.</p>
                    <p>On top of that, a large portion of the products AJ Produkter sells come in many different sizes and combinations to create unique solutions for their customers.</p>
                    <p>Selling unique, complex products without physical showrooms is not easy. </p>
                    <p>To help improve conversions, AJ Produkters marketing team are continually creating inspirational product content and guides to educate their customers whilst shopping online. The first step to a better digital shopping experience.</p>
                </div>
            </CaseInfo>

            <Quote>
                <StaticImage
                    className="case-quote"
                    src="../../../images/customers/aj produkter/Henrik Fahlen.jpeg"
                    style={{
                        borderRadius: '100px'
                    }}
                    alt="Henrik Fahlén"
                    loading="eager"
                    placeholder="none"
                />
                <h2>“Finally, our marketing content is now <span>shoppable</span>”</h2>
                <p>Henrik Fahlén</p>
                <p>UX Lead @ AJ Produkter</p>
            </Quote>

            <CaseInfo reverse>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/tEmig35CDSs?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <CaseInfoText>
                    <h3>The Solution</h3>
                    <p>The Qiwio team helped AJ Produkters marketing team turn their inspirational product guides, into interactive 'shoppable' content.</p>
                    <p>Right inside of the product content in AJ Produkter's guides, hotspots were added. Some hotspots included product information and linked to the product pages for smoother navigation. Other hotspots were information hotspots, when clicked, gave extra tips to the customer about certain products. Giving the customer the right product information at the right time during their buying journey.</p>
                    <p>All of the product information is connected to AJ Produkters PIM solution, which allows their marketing team to dynamically sync their product information to their Qiwio account. Which allows them to create 'shoppable' content very fast.</p>
                    <p>Finally, using Qiwio's geolocation and automatic translation feature, all product information was seamlessly translated into the correct language for all 14 markets they operate in. Meaning, no need to duplicate content, every piece of interactive content, worked for all of their markets seamlessly.</p>
                </CaseInfoText>
            </CaseInfo>

            <Quote>
                <StaticImage
                    className="case-quote"
                    src="../../../images/customers/aj produkter/Henrik Fahlen.jpeg"
                    style={{
                        borderRadius: '100px'
                    }}
                    alt="Henrik Fahlén"
                    loading="eager"
                    placeholder="none"
                />
                <h2>“Qiwio has really helped us create a <span>smoother digital shopping experience</span> for our customers and allows us to stay ahead of our competitors!”</h2>
                <p>Henrik Fahlén</p>
                <p>UX Lead @ AJ Produkter</p>
            </Quote>

            <CaseInfo>
                <QiwioVideoContainer>
                    <QiwioVideo src="https://player.qiwio.io/image/g5w-Q5GnBAA?mode=embed" frameborder="0" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture" allowFullScreen></QiwioVideo>
                </QiwioVideoContainer>
                <CaseInfoText>
                    <h3>The Results</h3>
                    <p>This project resulted in a more interactive and engaging shopping experience for AJ Produkters customers when they were exploring their product guides.</p>
                    <p>Now, their customers can learn about their products in a more interactive way, whilst also being able to buy the correct product within 2 clicks from the product guides.</p>
                    <p>The outcome was 70% of their website visitors engaged with the interactive 'shoppable' content with 42% of their website visitors clicked on product hotspots within the 'shoppable' content and landed on product pages.</p>
                    <p>This data proves that the interactive shopping experience Qiwio has helped AJ Produkter create is more engaging and allows their customers to explore, find and purchase the right products, much faster.</p>
                </CaseInfoText>
            </CaseInfo>

            <CtaSection />
        </div>
    </Layout>
)
const HeroWrapper = styled.div`
    padding: 50px 20px 0 20px;

    @media screen and (min-width: 900px) {
        padding: 100px 50px 0 50px;
    }

    h2 {
        margin-bottom: 30px;

        @media screen and (min-width: 900px) {
            font-size: 2.5rem;
            max-width: 60vw;
            text-align: center;
            margin: 0 auto 30px auto;
        }
    }
`
const StatsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 900px) {
        padding: 100px 50px;
    }

    @media screen and (min-width: 1000px) {
        flex-direction: row;
    }
`
const Stat = styled.div`
    width: 100%;

    &:not(:last-child) {
        padding-bottom: 30px;
        border-bottom: 2px solid #2E2E39;

        @media screen and (min-width: 1000px) {
            border-bottom: none;
            border-right: 2px solid #2E2E39;
            padding-bottom: 0;
        }
    }

    &:not(:first-child) {
        padding-top: 30px;

        @media screen and (min-width: 1000px) {
            padding-top: 0;
        }
    }

    h2 {
        margin-bottom: 5px;
        font-size: 3.125rem;
    }
`
const AboutCase = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        padding: 100px 50px 100px 200px;
    }

    div:first-child {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1000px) {
            min-width: 10vw;
        }
    }

    p {
        font-size: 1rem;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 30px;
        }

        @media screen and (min-width: 1000px) {
            margin-left: 100px;
        }
    }
`
const WebsiteContainer = styled.div`
    padding: 20px 0 50px 0;
    margin: 0 auto;
    display: grid;
    justify-content: center;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
        padding: 0 50px;
    }
`
const UseCaseSliderItemOverlay = styled.div`
    grid-area: 1/1;
    position: relative;
    background: linear-gradient(0deg, rgba(28,28,38,1) 0%, rgba(28,28,38,1) 5%, rgba(28,28,38,0.9) 20%, rgba(28,28,38,0) 100%);
`
const QiwioHeroVideoContainer = styled.div`
    width: 100%;
    border-radius: 4px;

    @media screen and (min-width: 1000px) {
        max-width: 60%;
        margin: 0 auto;
    }
`
const QiwioVideoContainer = styled.div`
    width: 100%;
`
const QiwioVideo = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: 4 / 3;
    overflow: hidden;

    @media screen and (max-width: 900px) {
        min-height: 220px;
    }
`
const SquareQiwioVideo = styled.iframe`
    border: none;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 1 / 1;
    border-radius: 10px;

    @media screen and (max-width: 900px) {
        min-height: 220px;
    }
`
const CaseInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    max-width: 2000px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
        flex-direction: ${({reverse}) => reverse ? 'row-reverse' : 'row'};
        padding: 100px 50px;
    }

    h3 {
        margin: 30px 0 10px 0;
    }

    div:first-child {
        display: flex;
        justify-content: center;

        @media screen and (min-width: 1000px) {
            margin-right: ${({reverse}) => reverse ? '0' : '50px'};
            margin-left: ${({reverse}) => reverse ? '50px' : '0'};
        }
    }
    p {
        font-size: 1rem;
        margin-bottom: 10px;
    }
`
const CaseInfoText = styled.div`
    @media screen and (min-width: 1000px) {
        max-width: 50%;
    }
`
const Quote = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 10px 100px 10px;

    @media screen and (min-width: 1000px) {
        max-width: 80vw;
        margin: 0 auto;
        padding: 100px 50px
    }
    @media screen and (min-width: 1800px) {
        max-width: 1400px;
        margin: 0 auto;
    }

    h2 {
        text-align: center;
        font-weight: 400;
        font-family: 'Barlow';
        margin-bottom: 10px;
    }

    span {
        color: #ff5679;
    }

    p {
        font-family: 'Barlow Semi Condensed';
        text-align: center;
    }
    p:last-child {
        font-family: 'Menlo';
        font-size: 0.875rem;

    }
`
export default AJProdukterCaseStudyPage